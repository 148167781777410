@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-[#111] text-gray-300 font-inter;
}

@layer components {
  .sub-menu {
    @apply absolute top-[4.2rem] p-[15px] rounded-[6px] origin-[50%_-170px] backdrop-blur bg-white/[0.04];
  }
}

@layer utilities {
  .flex-center {
    @apply flex items-center;
  }
  .flex-center-between {
    @apply flex items-center justify-between;
  }
}